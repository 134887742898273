import React, {useState} from "react";
import { Container} from "react-bootstrap";
import {navigate} from 'gatsby'
import PageWrapper from "../../../components/PageWrapper";
import { Section} from "../../../components/Core";
import Header from "../../../components/HeaderPro";
import Footer from "../../../components/FooterPro";
import {Helmet} from "react-helmet";
import styled from 'styled-components';

function Startabusiness() {

  const [Q1, setQ1] = useState('')
  const [Q1BGYes, setQ1BGYes] = useState('#e7e6e6')
  const [Q1BGNo, setQ1BGNo] = useState('#e7e6e6')
  const [Q1ColorYes, setQ1ColorYes] = useState('black')
  const [Q1ColorNo, setQ1ColorNo] = useState('black')

  const [Q2, setQ2] = useState('')
  const [Q2BGYes, setQ2BGYes] = useState('#e7e6e6')
  const [Q2BGNo, setQ2BGNo] = useState('#e7e6e6')
  const [Q2ColorYes, setQ2ColorYes] = useState('black')
  const [Q2ColorNo, setQ2ColorNo] = useState('black')

  const [Q3, setQ3] = useState('')
  const [Q3BGYes, setQ3BGYes] = useState('#e7e6e6')
  const [Q3BGNo, setQ3BGNo] = useState('#e7e6e6')
  const [Q3ColorYes, setQ3ColorYes] = useState('black')
  const [Q3ColorNo, setQ3ColorNo] = useState('black')

  const [Q4, setQ4] = useState('')
  const [Q4BGYes, setQ4BGYes] = useState('#e7e6e6')
  const [Q4BGNo, setQ4BGNo] = useState('#e7e6e6')
  const [Q4ColorYes, setQ4ColorYes] = useState('black')
  const [Q4ColorNo, setQ4ColorNo] = useState('black')

  const [Q5, setQ5] = useState('')
  const [Q5BGYes, setQ5BGYes] = useState('#e7e6e6')
  const [Q5BGNo, setQ5BGNo] = useState('#e7e6e6')
  const [Q5ColorYes, setQ5ColorYes] = useState('black')
  const [Q5ColorNo, setQ5ColorNo] = useState('black')

  const [Q6, setQ6] = useState('')
  const [Q6BGYes, setQ6BGYes] = useState('#e7e6e6')
  const [Q6BGNo, setQ6BGNo] = useState('#e7e6e6')
  const [Q6ColorYes, setQ6ColorYes] = useState('black')
  const [Q6ColorNo, setQ6ColorNo] = useState('black')

  const [Q7, setQ7] = useState('')
  const [Q7BGYes, setQ7BGYes] = useState('#e7e6e6')
  const [Q7BGNo, setQ7BGNo] = useState('#e7e6e6')
  const [Q7ColorYes, setQ7ColorYes] = useState('black')
  const [Q7ColorNo, setQ7ColorNo] = useState('black')


  const YesButton = styled.button`
  max-width: 450px;
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.backgroundColor};
  height: 70px;
  margin-left: 30px;
  margin-bottom: 30px;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 25px;
  color: ${props => props.color};
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  
  :focus{
    box-shadow: ${props => props.focus};
    outline: 0px;
  }
  :hover{
    background-color: rgb(255, 193, 7); 
 
  }
  :visited{
    background-color: rgb(255, 193, 7); 
  }
  `;


  const qOne = () => {
      console.log('Q1', Q1)
      console.log('Q2', Q2)
      console.log('Q3', Q3)
      console.log('Q4', Q4)
      console.log('Q5', Q5)
      console.log('Q6', Q6)
      console.log('Q7', Q7)


      if(Q1 === ''){
        return (
          <div style={{marginTop: 150, textAlign: 'center'}}>
          <h3 style={{fontSize: 40, color: 'white'}}>Une banque pro - quelle type de structure ?</h3>
          <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
          <YesButton backgroundColor={Q1BGYes} color={Q1ColorYes} onClick={(c) => {setQ1('SA'); setQ1BGYes('rgb(255, 193, 7)'); setQ1BGNo('#e7e6e6'); setQ1ColorYes('white'); setQ1ColorNo('black')}}>SA</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('SAS / SASU'); setQ1BGNo('rgb(255, 193, 7)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>SAS / SASU</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('SARL / EURL'); setQ1BGNo('rgb(255, 193, 7)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>SARL / EURL</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('SC / SCI'); setQ1BGNo('rgb(255, 193, 7)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>SC / SCI</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('EIRL'); setQ1BGNo('rgb(255, 193, 7)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>EIRL</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('Micro-Entreprise'); setQ1BGNo('rgb(255, 193, 7)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>Micro-Entreprise</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('Holding'); setQ1BGNo('rgb(255, 193, 7)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>Holding</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('SEL'); setQ1BGNo('rgb(255, 193, 7)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>SEL</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('Association'); setQ1BGNo('rgb(255, 193, 7)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>Association</YesButton>
          </div>
          </div>
        )
      } else if (Q2 === ''){
        return (
          <div style={{marginTop: 150, textAlign: 'center'}}>
          <h3 style={{fontSize: 40, color: 'white'}}>Avez-vous besoin d'encaisser des chèques ?</h3>
          <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
          <YesButton backgroundColor={Q2BGYes} color={Q2ColorYes} onClick={(c) => {setQ2('Oui'); setQ2BGYes('rgb(255, 193, 7)'); setQ2BGNo('#e7e6e6'); setQ2ColorYes('white'); setQ2ColorNo('black')}}>Oui</YesButton>
          <YesButton backgroundColor={Q2BGNo} color={Q2ColorNo} onClick={(c) => {setQ2('Non'); setQ2BGNo('rgb(255, 193, 7)'); setQ2BGYes('#e7e6e6'); setQ2ColorNo('white'); setQ2ColorYes('black')}}>Non</YesButton>
          <YesButton backgroundColor={Q2BGNo} color={Q2ColorNo} onClick={(c) => {setQ2('Parfois'); setQ2BGNo('rgb(255, 193, 7)'); setQ2BGYes('#e7e6e6'); setQ2ColorNo('white'); setQ2ColorYes('black')}}>Parfois</YesButton></div>
          </div>
        )
      } else if (Q3 === ''){
        return (
          <div style={{marginTop: 150, textAlign: 'center'}}>
          <h3 style={{fontSize: 40, color: 'white'}}>Avez-vous besoin d'encaisser de l'espèce ?</h3>
          <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
          <YesButton backgroundColor={Q3BGYes} color={Q3ColorYes} onClick={(c) => {setQ3('Oui'); setQ3BGYes('rgb(255, 193, 7)'); setQ3BGNo('#e7e6e6'); setQ3ColorYes('white'); setQ3ColorNo('black')}}>Oui</YesButton>
          <YesButton backgroundColor={Q3BGNo} color={Q3ColorNo} onClick={(c) => {setQ3('Non'); setQ3BGNo('rgb(255, 193, 7)'); setQ3BGYes('#e7e6e6'); setQ3ColorNo('white'); setQ3ColorYes('black')}}>Non</YesButton>
          <YesButton backgroundColor={Q3BGNo} color={Q3ColorNo} onClick={(c) => {setQ3('Parfois'); setQ3BGNo('rgb(255, 193, 7)'); setQ3BGYes('#e7e6e6'); setQ3ColorNo('white'); setQ3ColorYes('black')}}>Parfois</YesButton>
          </div>
          </div>
        )
         } else if (Q4 === ''){
      return (
        <div style={{marginTop: 150, textAlign: 'center'}}>
        <h3 style={{fontSize: 40, color: 'white'}}>Nombre de collaborateurs / salariés ?</h3>
        <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
        <YesButton backgroundColor={Q4BGYes} color={Q4ColorYes} onClick={(c) => {setQ4('1'); setQ4BGYes('rgb(255, 193, 7)'); setQ4BGNo('#e7e6e6'); setQ4ColorYes('white'); setQ4ColorNo('black')}}>1</YesButton>
        <YesButton backgroundColor={Q4BGNo} color={Q4ColorNo} onClick={(c) => {setQ4('1 - 5'); setQ4BGNo('rgb(255, 193, 7)'); setQ4BGYes('#e7e6e6'); setQ4ColorNo('white'); setQ4ColorYes('black')}}>1 - 5</YesButton>
        <YesButton backgroundColor={Q4BGNo} color={Q4ColorNo} onClick={(c) => {setQ4("5 - 50"); setQ4BGNo('rgb(255, 193, 7)'); setQ4BGYes('#e7e6e6'); setQ4ColorNo('white'); setQ4ColorYes('black')}}>5 - 50</YesButton>
        <YesButton backgroundColor={Q4BGNo} color={Q4ColorNo} onClick={(c) => {setQ4("50+"); setQ4BGNo('rgb(255, 193, 7)'); setQ4BGYes('#e7e6e6'); setQ4ColorNo('white'); setQ4ColorYes('black')}}>50+</YesButton>
        </div>
        </div>
      )
       } else if (Q5 === ''){
    return (
      <div style={{marginTop: 150, textAlign: 'center'}}>
      <h3 style={{fontSize: 40, color: 'white'}}>S'agit-il d'une création d'entreprise ?</h3>
      <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
      <YesButton backgroundColor={Q5BGYes} color={Q5ColorYes} onClick={(c) => {setQ5('Oui'); setQ5BGYes('rgb(255, 193, 7)'); setQ5BGNo('#e7e6e6'); setQ5ColorYes('white'); setQ5ColorNo('black')}}>Oui</YesButton>
      <YesButton backgroundColor={Q5BGNo} color={Q5ColorNo} onClick={(c) => {setQ5('Non'); setQ5BGNo('rgb(255, 193, 7)'); setQ5BGYes('#e7e6e6'); setQ5ColorNo('white'); setQ5ColorYes('black')}}>Non</YesButton>
      </div>
      </div>
    )

} else if (Q6 === ''){
  return (
    <div style={{marginTop: 150, textAlign: 'center'}}>
    <h3 style={{fontSize: 40, color: 'white'}}>Où se situe votre siège social ? </h3>
    <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
    <YesButton backgroundColor={Q6BGYes} color={Q6ColorYes} onClick={(c) => {setQ6('France'); setQ6BGYes('rgb(255, 193, 7)'); setQ6BGNo('#e7e6e6'); setQ6ColorYes('white'); setQ6ColorNo('black')}}>France</YesButton>
    <YesButton backgroundColor={Q6BGNo} color={Q6ColorNo} onClick={(c) => {setQ6('Union Européenne'); setQ6BGNo('rgb(255, 193, 7)'); setQ6BGYes('#e7e6e6'); setQ6ColorNo('white'); setQ6ColorYes('black')}}>Union Européenne</YesButton>
    <YesButton backgroundColor={Q6BGNo} color={Q6ColorNo} onClick={(c) => {setQ6('Hors Zone Euro'); setQ6BGNo('rgb(255, 193, 7)'); setQ6BGYes('#e7e6e6'); setQ6ColorNo('white'); setQ6ColorYes('black')}}>Hors Zone Euro</YesButton>
    </div>
    </div>
  )

} 

else if (Q7 === ''){
  return (
    <div style={{marginTop: 150, textAlign: 'center'}}>
    <h3 style={{fontSize: 40, color: 'white'}}>Avez-vous déjà été refusé par une banque ?</h3>
    <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
    <YesButton backgroundColor={Q7BGYes} color={Q7ColorYes} onClick={(c) => {setQ7('Oui'); setQ7BGYes('rgb(255, 193, 7)'); setQ7BGNo('#e7e6e6'); setQ7ColorYes('white'); setQ7ColorNo('black')}}>Oui</YesButton>
    <YesButton backgroundColor={Q7BGNo} color={Q7ColorNo} onClick={(c) => {setQ7('Non'); setQ7BGNo('rgb(255, 193, 7)'); setQ7BGYes('#e7e6e6'); setQ7ColorNo('white'); setQ7ColorYes('black')}}>Non</YesButton>
    </div>
    </div>
  )
}



// else if (Q8 === ''){
//   return (
//     <div style={{marginTop: 150, textAlign: 'center'}}>
//     <h3 style={{fontSize: 40, color: 'white'}}>Quel type de carte bancaire cherchez-vous ?</h3>
//     <div style={{marginTop: 50, textAlign: 'center'}}>
//     <YesButton backgroundColor={Q8BGYes} color={Q8ColorYes} onClick={(c) => {setQ8('Carte prépayée'); setQ8BGYes('rgb(255, 193, 7)'); setQ8BGNo('#e7e6e6'); setQ8ColorYes('white'); setQ8ColorNo('black')}}>Carte prépayée</YesButton>
//     <YesButton backgroundColor={Q8BGNo} color={Q8ColorNo} onClick={(c) => {setQ8('Visa / Mastercard'); setQ8BGNo('rgb(255, 193, 7)'); setQ8BGYes('#e7e6e6'); setQ8ColorNo('white'); setQ8ColorYes('black')}}>Visa / Mastercard</YesButton>
//     <YesButton backgroundColor={Q8BGNo} color={Q8ColorNo} onClick={(c) => {setQ8('Premier / Gold'); setQ8BGNo('rgb(255, 193, 7)'); setQ8BGYes('#e7e6e6'); setQ8ColorNo('white'); setQ8ColorYes('black')}}>Premier / Gold</YesButton>
//     <YesButton backgroundColor={Q8BGNo} color={Q8ColorNo} onClick={(c) => {setQ8('Infinite / World Elite'); setQ8BGNo('rgb(255, 193, 7)'); setQ8BGYes('#e7e6e6'); setQ8ColorNo('white'); setQ8ColorYes('black')}}>Infinite / World Elite</YesButton>
   
//     </div>
//     </div>
//   )
// } else if (Q9 === ''){
//   return (
//     <div style={{marginTop: 150, textAlign: 'center'}}>
//     <h3 style={{fontSize: 40, color: 'white'}}>Le plus important pour vous c'est ?</h3>
//     <div style={{marginTop: 50, textAlign: 'center'}}>
//     <YesButton backgroundColor={Q9BGYes} color={Q9ColorYes} onClick={(c) => {setQ9('Une assurance voyage'); setQ9BGYes('rgb(255, 193, 7)'); setQ9BGNo('#e7e6e6'); setQ9ColorYes('white'); setQ9ColorNo('black')}}>Une assurance voyage</YesButton>
//     <YesButton backgroundColor={Q9BGNo} color={Q9ColorNo} onClick={(c) => {setQ9('Une conciergerie'); setQ9BGNo('rgb(255, 193, 7)'); setQ9BGYes('#e7e6e6'); setQ9ColorNo('white'); setQ9ColorYes('black')}}>Une conciergerie</YesButton>
//     <YesButton backgroundColor={Q9BGNo} color={Q9ColorNo} onClick={(c) => {setQ9('La banque la moins chère'); setQ9BGNo('rgb(255, 193, 7)'); setQ9BGYes('#e7e6e6'); setQ9ColorNo('white'); setQ9ColorYes('black')}}>La banque la moins chère</YesButton>
//     </div>
//     </div>
//   )

// } 

else if (Q1 === 'SA' && Q7 !== ''){
  navigate('/banque-pro/sas-sasu/')
}
else if (Q1 === 'SAS / SASU' && Q7 !== ''){
  navigate('/banque-pro/sas-sasu/')
}
else if (Q1 === 'SARL / EURL' && Q7 !== ''){
  navigate('/banque-pro/sas-sasu/')
}
else if (Q1 === 'SC / SCI' && Q7 !== ''){
  navigate('/banque-pro/sci/')
}
else if (Q1 === 'EIRL' && Q7 !== ''){
  navigate('/banque-pro/sci/')
}
else if (Q1 === 'Micro-Entreprise' && Q7 !== ''){
  navigate('/banque-pro/auto-entrepreneurs/')
}
else if (Q1 === 'Holding' && Q7 !== ''){
  navigate('/banque-pro/sci/')
}
else if (Q1 === 'SEL' && Q7 !== ''){
  navigate('/banque-pro/sci/')
}
else if (Q1 === 'Association' && Q7 !== ''){
  navigate('/banque-pro/association/')
}




      

      
 
}



    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Comparateur de banques pros & entreprises - BankBank</title>
                <meta name="description" content="Répondez à 5 questions pour déterminer la meilleure banque pour votre entreprise. Comparez les frais bancaires et les services de gestion." />
        </Helmet>
      	<Header />
      	<Section style={{minHeight: 1000, background: 'linear-gradient(135deg,#29313d 60%,#29323d 37%,#000 90%)'}}>
      	<Container style={{maxWidth: 1280}}>


{qOne()}


		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default Startabusiness
